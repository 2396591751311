import React from "react"
import {useDispatch, useSelector} from "react-redux"

import {createProject} from "../../Projects/api"
import {receiveProjects} from "../../Projects/actions/projectsActions"

import MultipleDDList from "../../../toolkits/MultipleDDList"
import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"


export const ProjectsDDList = ({onChange, setUserMessage, setShowAssignProjectPopup, ...props}) => {
    const dispatch = useDispatch()

    const userProjectOptions = useSelector((state) => state.profile.projects)

    return (
        <MultipleDDList
            creatable={true}
            onChange={onChange}
            username={props.username}
            onUpdate={(option) => {
                !userProjectOptions.map(project => project.project.id).includes(option.value)
                    ? setShowAssignProjectPopup(option)
                    : null
            }}
            onCreateOption={(new_option) =>
                new Promise((resolve, reject) => {
                    createProject({name: new_option})
                        .then((response) => {
                            dispatch(receiveProjects())
                            setUserMessage({message: "Project was successfully created!", code: SUCCESS_CODE})
                            setShowAssignProjectPopup(response.data)
                            resolve({value: response.data.id, label: response.data.name})
                        })
                        .catch(error => {
                            console.log(error)
                            setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                            reject(error)
                        })
                })
            }
            {...props}
        />
    )
}

export default ProjectsDDList
