import React from "react"
import DDList from "../../../toolkits/DDList/DDList"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import {PRODUCT_STATUS} from "../../../constants/other"
import ToggleOnIcon from "@mui/icons-material/ToggleOn"
import ToggleOffIcon from "@mui/icons-material/ToggleOff"

const ItemCard = ({submitDisabled, loading, isEdit, product, setProduct, handleSubmitProduct}) => {
    return (
        <div className="create-product-right-item">
            <div className="create-product-right-item-title t-s6">Item</div>
            <DDList
                text_label="Status"
                listOptions={Object.values(PRODUCT_STATUS).filter(status => status !== PRODUCT_STATUS.DELETED && status !== PRODUCT_STATUS.ALL).map((status) => ({value: status, label: status}))}
                defaultValue={{value: product.status, label: product.status}}
                onChange={(status) => setProduct({...product, status: status.value})}
            />
            <div className="create-product-right-item-public t-b1"><span>Public</span><span onClick={() => setProduct(prevState => ({...prevState, public: !prevState.public}))}>{product.public ? <ToggleOnIcon className="active"/> : <ToggleOffIcon className="inactive"/>}</span></div>
            <SmallButton loading={loading} disabled={submitDisabled} onClick={() => handleSubmitProduct()}>{isEdit ? "Save" : "Create"}</SmallButton>
        </div>
    )
}

export default ItemCard