import {
    GET_TIME_RECORD_TEMPLATES_REQUEST,
    GET_TIME_RECORD_TEMPLATES_SUCCESS,
    GET_TIME_RECORD_TEMPLATES_FAILURE
} from "../const"

const initialState = {
    items:[],
    loading: false,
}

const handlers = {
    [GET_TIME_RECORD_TEMPLATES_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_TIME_RECORD_TEMPLATES_SUCCESS]: (state, { payload: { timeRecordTemplates } }) => ({
        ...state,
        items: timeRecordTemplates,
        loading: false,
    }),
    [GET_TIME_RECORD_TEMPLATES_FAILURE]: (state) => ({ ...state, loading: false }),
    DEFAULT: (state) => state,
}

const timeRecordTemplatesReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default timeRecordTemplatesReducer