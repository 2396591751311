import "./index.scss"

import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import Popup from "reactjs-popup"
import {useDispatch, useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import {receiveEmployeePositions, receiveNextPageEmployeePositions} from "./actions/EmployeePositionsActions"

import Protected from "../../components/Protected/Protected"
import EmployeePositionsTable from "./components/EmployeePositionsTable"
import CreateUpdatePositionPopup from "./components/CreateUpdatePositionPopup"

import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import SearchForm from "../../toolkits/SearchForm/SearchForm"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"

import {PAGE_SIZE} from "../../constants/other"
import {PERMISSIONS} from "../../constants/permissions"


const EmployeePositions = () => {
    const dispatch = useDispatch()

    const {
        tableLoading,
        employeePositions,
        total,
    } = useSelector((state) => state.employeePositions)
    
    const [currentPage, setCurrentPage] = useState(1)
    const [userMessage, setUserMessage] = useState(null)

    const [searchKey, setSearchKey] = useState(null)
    const [addNewPositionPopup, setAddNewPositionPopup] = useState(false)

    const getParams = (page) => ({
        page: page || currentPage,
        name: searchKey,
        size: PAGE_SIZE,
    })

    const getEmployeePositions = (page) => {
        const params = getParams(page)

        dispatch(receiveEmployeePositions(params))
        setCurrentPage((page || currentPage) + 1)
    }

    const getNextEmployeePositions = () => {
        const params = getParams()

        dispatch(receiveNextPageEmployeePositions(params))
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => { 
        getEmployeePositions(1)
    }, [])

    useEffect(() => {
        if (searchKey !== null) {
            const delayDebounceFn = setTimeout(() => {
                getEmployeePositions(1)
            }, 500)
            
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchKey])

    return (
        <>
            <Helmet>
                <title>Employee Positions - Newsoft Inside</title>
            </Helmet>

            <div className="employee-positions">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="employee-positions-header">
                    <SearchForm
                        value={searchKey}
                        onChange={(e) => setSearchKey(e.target.value)}
                        onClick={() => setSearchKey("")}
                    />
                    <Protected permissions={[PERMISSIONS.WRITE_EMPLOYEE_POSITION]}>
                        <div className="employee-positions-header-button">
                            <SmallButton onClick={() => setAddNewPositionPopup(true)}> ADD NEW POSITION </SmallButton>
                        </div>
                    </Protected>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={getNextEmployeePositions}
                    hasMore={!tableLoading && employeePositions.length < total}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={employeePositions.length}
                    useWindow={false}
                >
                    {tableLoading ? <StyledCircularProgress/> :
                        <div className="employee-positions-content">
                            <EmployeePositionsTable
                                employeePositions={employeePositions}
                                refreshTable={() => getEmployeePositions(1)}
                                setUserMessage={setUserMessage}
                            />
                        </div>
                    }
                </InfiniteScroll>
            </div>

            <Popup open={addNewPositionPopup} closeOnDocumentClick={false} onClose={() => setAddNewPositionPopup(false)} modal>
                {close => (
                    <CreateUpdatePositionPopup
                        refreshTable={() => getEmployeePositions(1)}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>

        </>)
}

export default EmployeePositions
