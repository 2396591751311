import { PAGE_SIZE, PRODUCT_STATUS, PRODUCTS_SORT_OPTIONS} from "../../../constants/other"
import {createSlice} from "@reduxjs/toolkit"


export const shopSlice = createSlice({
    name: "shop",
    initialState: {
        confettiFired: false,
        confettiDisabled: false,
        params: {
            page: 1,
            sortBy: PRODUCTS_SORT_OPTIONS.NEWEST_FIRST,
            categoryFilter: "All",
        },
        pageProducts: {},
        categories: [],
    },
    reducers: {
        setConfettiFired: (state) => {
            state.confettiFired = true
        },
        disableConfetti: (state) => {
            state.confettiDisabled = true
        },
        setSortBy: (state, action) => {
            state.pageProducts = {}
            state.params = {
                ...state.params,
                sortBy: action.payload,
                page: 1,
            }
        },
        setCategoryFilter: (state, action) => {
            state.pageProducts = {}
            state.params.categoryFilter = action.payload
            state.params.page = 1
        },
        loadCategories: (state, action) => {
            state.categories = action.payload
        },
        loadProductsPage(state, action) {
            state.pageProducts[action.payload.page] = action.payload.products
        },
        moveToNextPage(state) {
            state.params.page = state.params.page + 1
        },
        reset: (state) => {
            state.pageProducts= {}
            state.params = {
                ...state.params,
                page: 1,
            }
        },
    },
    selectors: {
        getConfettiFired: (state) => state.confettiFired,
        getConfettiDisabled: (state) => state.confettiDisabled,
        getCategoryFilter: (state) => state.params.categoryFilter,
        getSortBy: (state) => state.params.sortBy,
        getParameters: (state) => ({
            size: PAGE_SIZE,
            page: state.params.page,
            category_ids: state.params.categoryFilter !== "All" ? state.categories?.find((category) => category.name === state.params.categoryFilter)?.id : undefined,
            excluded_statuses: PRODUCT_STATUS.DELETED,
            order_by: state.params.sortBy.value,
        }),
        getProducts: (state) => {
            return Object.entries(state.pageProducts)
                .sort(([key1], [key2]) => key1 > key2 ? 1 : -1)
                .reduce((acc, [, value]) => [...acc, ...value], [])
        },
    }
})

export const { reset, setConfettiFired, disableConfetti, loadCategories, moveToNextPage, loadProductsPage, setSortBy, setCategoryFilter } = shopSlice.actions
export const { getConfettiFired, getConfettiDisabled, getParameters, getProducts, getSortBy, getCategoryFilter } = shopSlice.selectors
