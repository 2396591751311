import {
    GET_DAY_TIME_RECORDS_SUCCESS,
    GET_DAY_TIME_RECORDS_REQUEST,
    GET_DAY_TIME_RECORDS_FAILURE
} from "../const"

const initialState = {
    dayTimeRecords: [],
    loading: false
}

const handlers = {
    [GET_DAY_TIME_RECORDS_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_DAY_TIME_RECORDS_SUCCESS]: (state, { payload: { dayTimeRecords } }) => ({
        ...state,
        dayTimeRecords: dayTimeRecords,
        loading: false,
    }),
    [GET_DAY_TIME_RECORDS_FAILURE]: (state) => ({ ...state, loading: false }),

    DEFAULT: (state) => state,
}

const DayTimeRecordsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default DayTimeRecordsReducer