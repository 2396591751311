import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

import {receiveClients} from "../actions/ClientsActions"
import {createClient} from "../api"

import CreatableDDList from "../../../toolkits/CreatableDDList/CreatableDDList"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"


export const ClientsDDList = ({setClient, setUserMessage, ...props}) => {
    const dispatch = useDispatch()

    const {clients} = useSelector((state) => state.clients)


    useEffect(() => {
        dispatch(receiveClients())
    }, [])

    return (
        <CreatableDDList
            text_label="Client"
            placeholder=""
            listOptions={clients.map((client) => ({value: client.id, label: client.name}))}
            onChange={client => {setClient({...client})}}
            onCreateOption={(new_option) => {
                createClient({name: new_option})
                    .then((response) => {
                        setClient({value: response.data.id, label: response.data.name})
                        dispatch(receiveClients())
                        setUserMessage({message: "Client was successfully created!", code: SUCCESS_CODE})
                    })
                    .catch(error => {
                        console.log(error)
                        setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                    })
            }}
            {...props}
        />
    )
}

export default ClientsDDList
