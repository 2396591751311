import React, {useEffect} from "react"
import SettingsEnabled from "../../../components/SettingsEnabled/SettingsEnabled"
import {SETTINGS} from "../../../constants/other"
import {SensitiveInfo} from "../../../components/SensitiveInfo/SensitiveInfo"
import Newcoin from "../../../toolkits/Newcoin"
import {useDispatch, useSelector} from "react-redux"
import {receiveUserNewcoinsSpent} from "../../Profile/actions/userActions"

const SpendCard = ({user}) => {
    const dispatch = useDispatch()
    const spent = useSelector((state) => state.profile.newcoinsSpent)

    useEffect(() => {
        if (user) {
            dispatch(receiveUserNewcoinsSpent(user.id))
        }
    }, [])

    return (
        <SettingsEnabled settingsNames={[SETTINGS.NEWCOINS_ENABLED]}>
            <div className="spend-card">
                <h3 className="t-s6 spend-card-title">SPENT</h3>
                <h1 className="t-h1">
                    <SensitiveInfo className="sensitive-info">
                        <div className="spend-card-newcoins t-b5">
                            {Math.abs(spent)} <Newcoin type={"withdrawn"}/>
                        </div>
                    </SensitiveInfo>
                </h1>
            </div>
        </SettingsEnabled>
    )
}

export default SpendCard