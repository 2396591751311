import rootReducer from "./reducer"
import {configureStore} from "@reduxjs/toolkit"
import {productsApi} from "../api/products"

/**
 * @desc Store that lets you read state, dispatch actions, and subscribe to changes.
 * @const
 * @type {object}
 */
const store = configureStore(
    {
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: true,
                immutableCheck: false,
                serializableCheck: false,
            }).concat([
                productsApi.middleware,
            ]),
    }

)

window.store = store

export default store
