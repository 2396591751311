import "./index.scss"

import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import {useDispatch, useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import {
    receiveOnboardingRequests,
    receiveNextPageOnboardingRequests
} from "./actions/onboardingRequestsActions"

import OnboardingRequestsTable from "./components/OnboardingRequestsTable"

import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import {PAGE_SIZE} from "../../constants/other"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import SearchForm from "../../toolkits/SearchForm/SearchForm"
import {useCustomHistory} from "../../hooks/useCustomHistory"


const OnboardingRequests = () => {
    const history = useCustomHistory()

    const dispatch = useDispatch()

    const [userMessage, setUserMessage] = useState(null)
    const [searchKey, setSearchKey] = useState(history.location.state?.username ? history.location.state.username : null)
    const [currentPage, setCurrentPage] = useState(1)
    const [loadCurrentUserRequests, setLoadCurrentUserRequests] = useState(!history.location.state?.username)

    const {
        tableLoading,
        onboardingRequests,
        total,
    } = useSelector((state) => state.onboardingRequests)

    const getOnboardingRequests = (page) => {
        page = page ? page : currentPage
        const params = {
            page: page,
            size: PAGE_SIZE,
            username: searchKey,
        }

        dispatch(receiveOnboardingRequests(params))
        setCurrentPage(page + 1)
    }

    const getNextOnboardingRequests = (page) => {
        const params = {
            page: page,
            size: PAGE_SIZE,
            username: searchKey,
        }

        dispatch(receiveNextPageOnboardingRequests(params))
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        getOnboardingRequests(1)
        if (!loadCurrentUserRequests) {
            setLoadCurrentUserRequests(true)
        }
    }, [])

    useEffect(() => {
        if (searchKey !== null && loadCurrentUserRequests) {
            const delayDebounceFn = setTimeout(() => {
                getOnboardingRequests(1)
            }, 500)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchKey])
    return (
        <>
            <Helmet>
                <title>Onboarding requests - Newsoft Inside</title>
            </Helmet>
            <div className="onboarding-requests">
                <div>{userMessage &&
                    <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                }
                </div>
                <div className="onboarding-requests-header">
                    <SearchForm value={searchKey}
                        onClick={() => setSearchKey("")}
                        onChange={(e) => setSearchKey(e.target.value)}
                    />
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={getNextOnboardingRequests}
                    hasMore={!tableLoading && onboardingRequests.length < total}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={onboardingRequests.length}
                    useWindow={false}
                >
                    {tableLoading? <StyledCircularProgress/> :
                        <div className="onboarding-requests-content">
                            <OnboardingRequestsTable
                                onboardingRequests={onboardingRequests}
                                refreshTable={() => getOnboardingRequests(1)}
                                setUserMessage={setUserMessage}
                            />
                        </div>
                    }

                </InfiniteScroll>
            </div>
        </>
    )
}

export default OnboardingRequests