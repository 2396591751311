import {
    getParameters,
    getProducts,
    loadProductsPage,
    getCategoryFilter,
    setCategoryFilter,
    setSortBy,
    getSortBy,
    loadCategories,
    moveToNextPage
} from "../reducers/shopReducer"
import {useDispatch, useSelector} from "react-redux"
import {useCallback, useEffect, useMemo} from "react"
import {PAGE_SIZE} from "../../../constants/other"
import {useGetProductCategoriesQuery, useGetProductsQuery} from "../../../api/products"


export default () => {
    const params = useSelector(getParameters)
    const products = useSelector(getProducts)
    const categoryFilter = useSelector(getCategoryFilter)
    const sortBy = useSelector(getSortBy)
    const dispatch = useDispatch()

    const {data: categories} = useGetProductCategoriesQuery({})

    const categoryStatuses = ["All", ...categories?.map(category => category.name) || []]

    const {data, isLoading, isFetching} = useGetProductsQuery(params)
    const total = data?.total

    const maxPage = useMemo(() => total ? Math.ceil(total / PAGE_SIZE) : null, [total])

    const loading = useMemo(() => isLoading || isFetching, [isFetching, isLoading])

    const loadNextPage = useCallback(() => {
        if (!loading && params.page < maxPage) {
            dispatch(moveToNextPage())
        }
    }, [params.page, maxPage, loading])

    useEffect(() => {
        dispatch(loadCategories(categories))
    }, [categories, dispatch])

    useEffect(() => {
        if (data?.items && data.items.length !== 0 && !loading && params.page) {
            dispatch(loadProductsPage({page: params.page, products: data?.items}))
        }
    }, [params, data, loading, loadProductsPage, dispatch])

    return {
        products,
        total,
        hasMore: params.page < maxPage,
        loading,
        loadNextPage,
        categoryStatuses,
        categoryFilter,
        setCategoryFilter: (categoryFilter) => dispatch(setCategoryFilter(categoryFilter)),
        sortBy,
        setSortBy: (sortBy) => dispatch(setSortBy(sortBy)),
    }
}
