import React, {useEffect, useState} from "react"
import Popup from "reactjs-popup"
import {Helmet} from "react-helmet"
import {useMediaQuery} from "react-responsive"
import {useDispatch, useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import {receiveNextPageUserSalaries, receiveUserSalaries} from "../actions/salaryHistoryActions"
import {receiveUserProjects} from "../../Profile/actions/projectsActions"
import {receiveProjects} from "../../Projects/actions/projectsActions"

import {SalaryHistoryTable} from "./SalaryHistoryTable"
import CreateUpdateSalaryPopup from "./CreateUpdateSalaryPopup"
import Protected from "../../../components/Protected/Protected"

import AlertMessage from "../../../toolkits/AlertMessage/AlertMessage"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CircularProgress from "../../../toolkits/CircularProgress/CircularProgress"
import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"

import {getDraft, saveDraft} from "../../../utils/localstorage.utils"

import UserImage from "../../../assets/images/user_photo.png"
import CloseButtonIcon from "@mui/icons-material/Close"

import {DESKTOP_WIDTH, PAGE_SIZE} from "../../../constants/other"

import {useCustomHistory} from "../../../hooks/useCustomHistory"
import {PERMISSIONS} from "../../../constants/permissions"

const SalaryHistory = () => {
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})
    const dispatch = useDispatch()
    const {
        tableLoading,
        salaries,
        total,
    } = useSelector((state) => state.salaryHistory)
    const {myInfo} = useSelector((state) => state.profile)
    const {location: {state}} = useCustomHistory()

    const userId = state?.user?.id || myInfo.id
    const username = state ?  `${state.user.first_name} ${state.user.last_name}` : `${myInfo.first_name} ${myInfo.last_name}`
    const imageUrl = state?.user?.image_url || (!state?.user && myInfo.image_url)
    const currentSalary = state ? state?.user?.salary : myInfo.salary

    const [currentPage, setCurrentPage] = useState(1)
    const [userMessage, setUserMessage] = useState(null)
    const [addNewSalaryPopup, setAddNewSalaryPopup] = useState(false)
    const [showDraftCreateSalary, setShowDraftCreateSalary] = useState(Object.values(getDraft(userId)?.createSalary ?? {}).length)

    
    const getParams = (page) => ({
        page: page || currentPage,
        size: PAGE_SIZE,
    })

    const getUserSalaries = (page) => {
        const params = getParams(page)

        dispatch(receiveUserSalaries(userId, params))
        setCurrentPage((page || currentPage) + 1)
    }

    const getNextUserSalaries = () => {
        const params = getParams()

        dispatch(receiveNextPageUserSalaries(params))
        setCurrentPage(currentPage + 1)
    }

    const clearDraftSalary = () => {
        saveDraft(userId, {createSalary: {}})
        setShowDraftCreateSalary(false)
    }

    useEffect(() => {
        getUserSalaries(1)
        dispatch(receiveProjects())
        dispatch(receiveUserProjects(userId))
    }, [userId])

    return (
        <>
            <Helmet>
                <title>Salary history - Newsoft Inside</title>
            </Helmet>
            <div className="salary-history">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} {...userMessage}/>
                    }
                </div>

                <div className="salary-history-header">
                    <Protected permissions={[PERMISSIONS.WRITE_SALARY]}>
                        <div className="salary-history-header-user">
                            <img alt="User image" src={imageUrl || UserImage}/>
                            <span className={`${isDesktop ? "t-s1" : "t-s3"}`}>{username}</span>
                        </div>
                        <div className="salary-history-header-button">
                            {(isDesktop && showDraftCreateSalary) ?
                                <div className="salary-history-header-draft">
                                    <div>
                                        <Popup
                                            trigger={<span className="t-b2 clickable">use salary draft</span>}
                                            closeOnDocumentClick={false}
                                            modal
                                        >
                                            {close => (
                                                <CreateUpdateSalaryPopup
                                                    disabledCurrency={currentSalary
                                                        ? {value: currentSalary?.currency.id, label: currentSalary?.currency.iso_code}
                                                        : null
                                                    }
                                                    userId={userId}
                                                    username={username}
                                                    create={true}
                                                    currentSalary={getDraft(userId)?.createSalary}
                                                    setShowDraftCreateSalary={setShowDraftCreateSalary}
                                                    refreshTable={() => getUserSalaries(1)}
                                                    setUserMessage={setUserMessage}
                                                    close={close}
                                                />
                                            )}
                                        </Popup>
                                        <CloseButtonIcon className="clear-draft-icon clickable" onClick={clearDraftSalary}/>
                                    </div>
                                </div> : <></>
                            }
                            <SmallButton onClick={() => setAddNewSalaryPopup(true)}> ADD NEW SALARY </SmallButton>
                        </div>
                    </Protected>
                </div>

                <InfiniteScroll
                    pageStart={0}
                    next={getNextUserSalaries}
                    hasMore={!tableLoading && salaries.length < total}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={salaries.length}
                    useWindow={false}
                >
                    {tableLoading ? <StyledCircularProgress/> :
                        <div className="salary-history-content">
                            <SalaryHistoryTable
                                username={username}
                                salaries={salaries}
                                setShowDraftCreateSalary={setShowDraftCreateSalary}
                                refreshTable={() => getUserSalaries(1)}
                                setUserMessage={setUserMessage}
                            />
                        </div>
                    }
                </InfiniteScroll>
            </div>

            <Popup
                open={addNewSalaryPopup}
                closeOnDocumentClick={false}
                onClose={() => setAddNewSalaryPopup(false)}
                modal
            >
                {close => (
                    <CreateUpdateSalaryPopup
                        disabledCurrency={currentSalary
                            ? {value: currentSalary?.currency.id, label: currentSalary?.currency.iso_code}
                            : null
                        }
                        userId={userId}
                        username={username}
                        create={true}
                        setShowDraftCreateSalary={setShowDraftCreateSalary}
                        refreshTable={() => getUserSalaries(1)}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
        </>)
}

export default SalaryHistory
