import React from "react"
import Popup from "reactjs-popup"

import InfoPopup from "../../../components/InfoPopup"
import DeleteClientPopup from "./DeleteClientPopup"
import CreateUpdateClientPopup from "./CreateUpdateClientPopup"

import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"

import Protected from "../../../components/Protected/Protected"

import {PERMISSIONS} from "../../../constants/permissions"


export const ClientsTable = ({clients, setUserMessage, refreshTable, i}) => {
    
    const clientsList = clients.map((c) => {
        const popupParams = {
            client: c,
            setUserMessage: setUserMessage,
            refreshTable: refreshTable,
        }
        const managerActions = (
            <>
                <Protected permissions={[PERMISSIONS.WRITE_CLIENT]}>
                    <Popup trigger={<span className="custom-scrollable-table-link"> edit </span>} modal>
                        {close => (
                            <CreateUpdateClientPopup {...popupParams} close={close}/>
                        )}
                    </Popup>
                    <Popup trigger={<span className="custom-scrollable-table-link"> delete </span>} modal>
                        {close => (
                            <DeleteClientPopup {...popupParams} close={close}/>
                        )}
                    </Popup>
                </Protected>
            </>
        )

        return (
            <tr key={`${i} ${c.id}`} className="custom-scrollable-table-row clients-content-table-row">
                <td>
                    <Popup trigger={
                        <span className="cell-limited-text clients-content-table-first-column t-s1">{c.name}</span>
                    } modal>
                        {close => (
                            <InfoPopup
                                title="Client"
                                value={c.name}
                                close={close}
                            />
                        )}
                    </Popup>
                </td>
                <td>
                    {managerActions}
                </td>
            </tr>
        )
    })
    
    return (
        <CustomScrollableTable>
            <table className="clients-content-table">
                <thead>
                    <tr>
                        <th><span className="custom-scrollable-table-head-span">CLIENT</span></th>
                        <th><span className="custom-scrollable-table-head-span">ACTIONS</span></th>
                    </tr>
                </thead>

                <tbody>
                    {clients.length > 0 ? clientsList : <EmptyResult/>}
                </tbody>
            </table>    
        </CustomScrollableTable>
    )
}

export default ClientsTable
