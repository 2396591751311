import React from "react"

import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"
import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"
import DefaultUserPhoto from "../../../assets/images/user_photo.png"
import {DD_MM_YYYY, PRODUCT_STATUS, PURCHASE_REQUEST_STATUS} from "../../../constants/other"
import Newcoin from "../../../toolkits/Newcoin"
import Popup from "reactjs-popup"
import ApprovePurchaseRequestPopup from "./ApprovePurchaseRequestPopup"
import {formatDate} from "../../../utils/datetime.utils"
import InfoPopup from "../../../components/InfoPopup"


const PurchaseRequestsTable = ({
    purchaseRequests,
    refreshTable,
    setUserMessage,
}) => {

    const renderPurchaseStatus = (purchaseRequest, tableActions) => {
        if (purchaseRequest.status === PURCHASE_REQUEST_STATUS.IN_REVIEW) {
            return tableActions
        }
        if (purchaseRequest.status === PURCHASE_REQUEST_STATUS.APPROVED) {
            return <span className="purchase-requests-content-table-green-span t-h3">APPROVED</span>
        }
        return <span className="purchase-requests-content-table-red-span t-h3">DECLINED</span>
    }
    
    const purchaseRequestsList = purchaseRequests.map((purchaseRequest, i) => {

        const tableActions = (purchaseRequest) => {
            const popupParams = {
                purchaseRequest: purchaseRequest,
                refreshTable: refreshTable,
                setUserMessage: setUserMessage,
            }

            return (
                <>
                    <Popup trigger={<span className="custom-scrollable-table-link"> decline </span>} modal>
                        {close => (
                            <ApprovePurchaseRequestPopup
                                {...popupParams}
                                approve={false}
                                close={close}
                            />
                        )}
                    </Popup>
                    <Popup trigger={<span className="custom-scrollable-table-link"> approve </span>} modal>
                        {close => (
                            <ApprovePurchaseRequestPopup
                                {...popupParams}
                                approve={true}
                                close={close}
                            />
                        )}
                    </Popup>
                </>
            )
        }

        return (
            <tr key={`${i} ${purchaseRequest.id}`}
                className="custom-scrollable-table-row purchase-requests-content-table-row">
                <td className="purchase-requests-content-table-first-column">
                    <img src={purchaseRequest.user.image_url || DefaultUserPhoto} alt="User Photo"/>
                    <span className="purchase-requests-content-table-first-column-employee"><span className="t-s1">{purchaseRequest.user.first_name}</span><span className="t-s1">{purchaseRequest.user.last_name}</span></span>
                </td>
                <td className="purchase-requests-content-table-date">
                    <span className="t-b2">{formatDate(purchaseRequest.created_at, DD_MM_YYYY)}</span>
                </td>
                <td className="purchase-requests-content-table-description">
                    <div className="t-s1">{purchaseRequest.product_variant.product.name}</div>
                    <span className="t-b2">{purchaseRequest.product_variant.formatted_options}</span>
                </td>
                <td className="purchase-requests-content-table-status">
                    <span className={`t-b2 purchase-requests-content-table-${[PRODUCT_STATUS.IN_STOCK, PRODUCT_STATUS.PRE_ORDER].includes(purchaseRequest.product_variant.product.status) ? "green-span" : "red-span"}`}>{purchaseRequest.product_variant.product.status}</span> <span>{purchaseRequest.product_variant.product.pre_order_delivery_time ? `(${purchaseRequest.product_variant.product.pre_order_delivery_time})` : ""}</span>
                </td>
                <td className="purchase-requests-content-table-amount">
                    <span className="t-b2">{purchaseRequest.quantity}</span>
                </td>
                <td className="purchase-requests-content-table-price">
                    <span className="t-b2">{purchaseRequest.total_price} <Newcoin type={"default"}/></span>
                </td>
                <td className="purchase-requests-content-table-reason">
                    <span className="t-b2">
                        <Popup trigger={<span className="cell-limited-text clickable"> {purchaseRequest.reason} </span>} modal>
                            {close => (
                                <InfoPopup
                                    title="Purchase Request Reason"
                                    value={purchaseRequest.reason}
                                    close={close}
                                />
                            )}
                        </Popup></span>
                </td>
                <td className="purchase-requests-content-table-last-column">
                    {renderPurchaseStatus(purchaseRequest, tableActions(purchaseRequest))}
                </td>
            </tr>
        )
    })

    return (
        <CustomScrollableTable>
            <table className="purchase-requests-content-table">
                <thead>
                    <tr>
                        <th><span className="custom-scrollable-table-head-span-first">EMPLOYEE</span></th>
                        <th className="purchase-requests-content-table-date"><span className="custom-scrollable-table-head-span">DATE</span></th>
                        <th className="purchase-requests-content-table-description"><span className="custom-scrollable-table-head-span">PURCHASE (DESCRIPTION)</span></th>
                        <th className="purchase-requests-content-table-status"><span className="custom-scrollable-table-head-span">STATUS (DELIVERY TIME)</span></th>
                        <th className="purchase-requests-content-table-amount"><span className="custom-scrollable-table-head-span">AMOUNT</span></th>
                        <th className="purchase-requests-content-table-price"><span className="custom-scrollable-table-head-span">TOTAL PRICE</span></th>
                        <th className="purchase-requests-content-table-reason"><span className="custom-scrollable-table-head-span">REASON</span></th>
                        <th><span className="custom-scrollable-table-head-span">ACTIONS</span></th>
                    </tr>
                </thead>
                <tbody>
                    {purchaseRequestsList.length > 0 ? purchaseRequestsList : <EmptyResult/>}
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}

export default PurchaseRequestsTable
