import {createApi} from "@reduxjs/toolkit/query/react"
import {httpMethod} from "../http"
import {API_V1_URL} from "../constants/other"


export const productsApi = createApi({
    reducerPath: "productsApi",
    baseQuery: ({method="get", path, params, data}) => httpMethod(method)(`${API_V1_URL}/${path}`, {params, data}),
    tagTypes: ["Products", "ProductCategories"],
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: (params) => ({
                path: "products/",
                params,
            }),
            providesTags: ["Products"]
        }),
        getProductCategories: builder.query({
            query: () => ({path: "product-categories/"}),
            providesTags: ["ProductCategories"]
        }),
        createProduct: builder.mutation({
            query: ({data}) => ({path: "products/", method: "POST", data}),
            invalidatesTags: ["Products"]
        }),
        updateProduct: builder.mutation({
            query: ({productId, data}) => ({path: `products/${productId}`, method: "PUT", data}),
            invalidatesTags: ["Products"]
        }),
        deleteProduct: builder.mutation({
            query: ({productId, params}) => ({path: `products/${productId}`, method: "DELETE", params}),
            invalidatesTags: ["Products"]
        }),
        createProductCategory: builder.mutation({
            query: ({data}) => ({path: "product-categories/", method: "POST", data}),
            invalidatesTags: ["ProductCategories"]
        }),
    })
})

export const {
    useGetProductsQuery,
    useGetProductCategoriesQuery,
    useCreateProductMutation,
    useUpdateProductMutation,
    useDeleteProductMutation ,
    useCreateProductCategoryMutation,
} = productsApi
