import http from "../../http"

import {API_V1_URL} from "../../constants/other"


const CLIENT_URL = `${API_V1_URL}/clients/`


export const getClients = (params) => http.get(CLIENT_URL, {params})

export const createClient = (payload) => http.post(CLIENT_URL, {data: payload})

export const updateClient = (clientId, data) => http.put(`${CLIENT_URL}${clientId}`, {data: data})

export const deleteClient = (clientId) => http.delete(`${CLIENT_URL}${clientId}`)
