import "./index.scss"

import React, {useEffect, useState} from "react"
import Popup from "reactjs-popup"
import {Helmet} from "react-helmet"
import {useDispatch, useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroller"

import {receiveNextPageProjects, receiveProjects} from "./actions/projectsActions"

import Protected from "../../components/Protected/Protected"
import ProjectsTable from "./components/ProjectsTable"
import CreateEditProjectPopup from "./components/CreateEditProjectPopup"

import SearchForm from "../../toolkits/SearchForm/SearchForm"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import SmallButton from "../../toolkits/SmallButton/SmallButton"

import {PAGE_SIZE} from "../../constants/other"
import {PERMISSIONS} from "../../constants/permissions"


const Projects = () => {
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1)

    const {
        loading,
        tableLoading,
        projects,
        total,
    } = useSelector((state) => state.projects)

    const [searchKey, setSearchKey] = useState(null)
    const [userMessage, setUserMessage] = useState(null)
    const [addNewProjectPopup, setAddNewProjectPopup] = useState(false)

    const getProjects = (page) => {
        page = page ? page : currentPage
        const params = {
            size: PAGE_SIZE,
            page,
            name: searchKey,
        }

        dispatch(receiveProjects(params))
        setCurrentPage(page + 1)
    }

    const getNextProjects = () => {
        const params = {
            size: PAGE_SIZE,
            page: currentPage,
            name: searchKey,
        }

        dispatch(receiveNextPageProjects(params))
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        getProjects(1)
    }, [])

    useEffect(() => {
        if (searchKey !== null) {
            const delayDebounceFn = setTimeout(() => {
                getProjects(1)
            }, 500)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchKey])

    return (
        <>
            <Helmet>
                <title> Projects - Newsoft Inside </title>
            </Helmet>
            <div className="projects">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message}
                            code={userMessage.code}/>
                    }
                </div>
                <div className="projects-header">
                    <div className="projects-header-search">
                        <SearchForm value={searchKey}
                            onClick={() => setSearchKey("")}
                            onChange={(e) => setSearchKey(e.target.value)}
                        />
                    </div>
                    <Protected permissions={[PERMISSIONS.WRITE_PROJECT]}>
                        <div className="projects-header-button">
                            <SmallButton onClick={() => setAddNewProjectPopup(true)}> ADD NEW PROJECT </SmallButton>
                        </div>
                    </Protected>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={getNextProjects}
                    hasMore={!tableLoading && projects.length < total}
                    loader={<CircularProgress key="circular-progress"/>}
                    datalength={projects.length}
                >
                    {loading ? <StyledCircularProgress/> :
                        <div className="projects-content">
                            <ProjectsTable
                                projects={projects}
                                refreshTable={() => getProjects(1)}
                                setUserMessage={setUserMessage}
                            />
                        </div>
                    }
                </InfiniteScroll>
            </div>

            <Popup open={addNewProjectPopup} closeOnDocumentClick={false} onClose={() => setAddNewProjectPopup(false)} modal>
                {close => (
                    <CreateEditProjectPopup
                        refreshTable={() => getProjects(1)}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>

        </>
    )
}

export default Projects
