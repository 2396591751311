import React, {useState} from "react"
import {useSelector} from "react-redux"
import SettingsEnabled from "../../../components/SettingsEnabled/SettingsEnabled"
import {SETTINGS} from "../../../constants/other"
import InfoIcon from "@mui/icons-material/Pending"
import {SensitiveInfo} from "../../../components/SensitiveInfo/SensitiveInfo"
import Newcoin from "../../../toolkits/Newcoin"
import Popup from "reactjs-popup"
import NewcoinsUsagePopup from "../../Profile/components/Popups/NewcoinsUsagePopup"

const BalanceCard = ({user}) => {
    const [showNewcoinsUsagePopup, setShowNewcoinsUsagePopup] = useState(false)
    const newcoins = useSelector((state) => state.profile.newcoins)

    return (
        <SettingsEnabled settingsNames={[SETTINGS.NEWCOINS_ENABLED]}>
            <div onClick={() => setShowNewcoinsUsagePopup(true)} className="balance-card">
                <h3 className="t-s6 balance-card-title">NEWCOINS</h3>
                <InfoIcon className="balance-card-dots clickable"/>
                <h1 className="t-h1">
                    <SensitiveInfo className="sensitive-info">
                        <div className="balance-card-newcoins t-b5">
                            {newcoins} <Newcoin type={"active"}/>
                        </div>
                    </SensitiveInfo>
                </h1>
            </div>

            <Popup open={showNewcoinsUsagePopup} onClose={() => setShowNewcoinsUsagePopup(false)} modal>
                {close => (
                    <NewcoinsUsagePopup
                        close={close}
                        user={user}
                    />
                )}
            </Popup>
        </SettingsEnabled>
    )
}

export default BalanceCard