import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import Popup from "reactjs-popup"

import {createSalary, updateUserSalary} from "../api"
import {receiveCurrentUser, receiveMyInfo} from "../../Profile/actions/userActions"

import AssignProjectPopup from "./AssignProjectPopup"
import ProjectsDDList from "./ProjectsDDList"
import ClientsDDList from "../../Clients/components/ClientsDDList"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import DDList from "../../../toolkits/DDList/DDList"
import TextInput from "../../../toolkits/TextInput/TextInput"
import SingleDatePicker from "../../../toolkits/Calendar/SingleDatePicker"
import Textarea from "../../../toolkits/Textarea/Textarea"

import {formatDate} from "../../../utils/datetime.utils"

import {DD_MM_YYYY, ERROR_CODE, INFO_ALERT, SALARY_TYPES, SUCCESS_CODE} from "../../../constants/other"

import {getDraft, saveDraft} from "../../../utils/localstorage.utils"
import {changeDateFormat, validateDate} from "../../../utils/datetime.utils"


const currencyOptions = [
    {id: 1, value: "USD"},
    {id: 2, value: "UAH"},
]

const salaryTypeOptions = [
    {label: "Primary", value: true},
    {label: "Secondary", value: false},
]


const CreateUpdateSalaryPopup = ({
    create,
    close,
    userId,
    username,
    setUserMessage,
    refreshTable,
    currentSalary,
    resetStartDate,
    setShowDraftCreateSalary,
}) => {
    const dispatch = useDispatch()

    const myInfo = useSelector(state => state.profile.myInfo)
    const projectOptions = useSelector((state) => state.projects.projects)
    const userProjectOptions = useSelector((state) => state.profile.projects)

    const [paymentType, setPaymentType] = useState(currentSalary
        ? {
            value: currentSalary?.salary_type,
            label: currentSalary?.salary_type
        } : {}
    )
    const [primary, setPrimary] = useState(currentSalary
        ? currentSalary?.primary
        : null
    )
    const [currency, setCurrency] = useState({
        value: currentSalary?.currency?.id || null,
        label: currentSalary?.currency?.iso_code || null
    })
    const [amount, setAmount] = useState(currentSalary
        ? currentSalary?.amount
        : null
    )
    const [startDate, setStartDate] = useState(resetStartDate
        ? formatDate(new Date())
        : currentSalary
            ? currentSalary?.start_date
            : null
    )

    const [endDate, setEndDate] = useState(resetStartDate
        ? null
        : currentSalary
            ? currentSalary?.end_date
            : null
    )
    const [note, setNote] = useState(currentSalary
        ? currentSalary?.note
        : null
    )
    const [client, setClient] = useState(currentSalary
        ? {
            value: currentSalary?.client?.id,
            label: currentSalary?.client?.name
        } : null
    )
    const [projects, setProjects] = useState(currentSalary
        ? currentSalary?.projects?.map(project =>
            ({label: project.name, value: project.id}))
        : null
    )

    const [showAssignProjectPopup, setShowAssignProjectPopup] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const formatedDate = (date) => {
        const result = changeDateFormat(date, DD_MM_YYYY, "YYYY-MM-DD")

        return validateDate(date, "YYYY-MM-DD")
            ? date
            : result !== "Invalid date" ? result : null
    } 

    const getProjectById = (projectId) =>
        projectOptions?.find(project => project.id === projectId)

    const getFormFields = () => {
        return ({
            ...(primary !== null ? {primary} : {}),
            ...(paymentType?.value ? {salary_type: paymentType.value} : {}),
            ...(currency?.value !== null ? {currency: {
                id: currency.value,
                iso_code: currency.label,
            }} : {}),
            ...(amount !== null ? {amount: Number(amount)} : {}),
            ...(client !== null? {client: {
                id: client.value,
                name: client.label,
            }} : {}),
            ...(projects?.length ? {projects: projects.map(project => ({
                id: project?.value || project,
                name: getProjectById(project?.value || project)?.name
            }))} : {}),
            ...(startDate ? {start_date: formatedDate(startDate)} : {}),
            ...(endDate ? {end_date: formatedDate(endDate)} : {}),
            ...(note ? {note} : {}),
        })
    }

    const isDisabledSubmit = () => {
        return !paymentType?.value || !currency?.value || !amount || !startDate || !client || !projects?.length || (
            paymentType?.value === currentSalary?.salary_type
            && currentSalary?.primary === primary
            && Number(amount) === currentSalary?.amount
            && currentSalary?.start_date === formatedDate(startDate)
            && currentSalary?.end_date === formatedDate(endDate)
            && note === currentSalary?.note
            && client.value === currentSalary?.client?.id
            && currentSalary?.projects?.length && currentSalary.projects.map(p => p.id).every(x => projects.indexOf(x) !== -1)
            && projects.every(p => currentSalary?.projects.map(x => x.id).indexOf(p) !== -1)
        ) || errorMessage
    }

    const handleSubmit = () => {

        const payload = {
            amount: amount,
            salary_type: paymentType.value,
            primary,
            currency_id: currency.value,
            user_id: userId,
            start_date: formatedDate(startDate),
            end_date: formatedDate(endDate),
            note: note,
            client_id: client.value,
            project_ids: [...new Set(projects)],
        }

        if(create){
            createSalary(payload)
                .then(() => {
                    dispatch(myInfo.id === userId ? receiveMyInfo(): receiveCurrentUser(userId))
                    refreshTable()
                    close(false)
                    setUserMessage({message: "Salary was successfully created!", code: SUCCESS_CODE})
                    setShowDraftCreateSalary(false)
                    saveDraft({createSalary: {}})
                })
                .catch(error => {
                    setErrorMessage({message: error.response.data.message, code: ERROR_CODE})
                })
        } else {
            updateUserSalary(currentSalary.id, payload)
                .then(() => {
                    dispatch(myInfo.id === currentSalary.user_id ? receiveMyInfo(): receiveCurrentUser(userId))
                    refreshTable()
                    close(false)
                    setUserMessage({message: "Salary was successfully changed!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    setErrorMessage({message: error.response.data.message, code: ERROR_CODE})
                })
        }
    }

    const saveSalaryDraft = () => {
        if (create) {
            const draftCreateSalary = getFormFields()
            const oldDraft = getDraft(userId)?.createSalary
                ? getDraft(userId).createSalary
                : {}
            create && saveDraft(userId, {
                createSalary: {...oldDraft, ...draftCreateSalary}
            })
            if (Object.values(draftCreateSalary).length) {
                setShowDraftCreateSalary(true)
                setUserMessage({message: "Salary draft was created.", code: SUCCESS_CODE, type: INFO_ALERT})
            }
        }
        close(false)
    }
    useEffect(() => {
        setErrorMessage(null)
    },[paymentType, primary, amount, startDate, endDate, client, projects])

    return (
        <>
            <CustomPopup
                text_title={create ? "Create Salary" : "Edit Salary"}
                className="update-salary-popup"
            >
                <DDList
                    text_label="Salary Type"
                    placeholder=""
                    defaultValue={
                        primary === true ? {label: "Primary", value: true}:
                            primary === false ? {label: "Secondary", value: false}: null
                    }
                    listOptions={salaryTypeOptions}
                    isDisabled={!create}
                    onChange={(salaryType) => setPrimary(salaryType.value)}
                />
                <DDList
                    text_label="Payment Type"
                    placeholder=""
                    defaultValue={paymentType}
                    listOptions={Object.keys(SALARY_TYPES).map((type) => ({value: SALARY_TYPES[type], label: SALARY_TYPES[type]}))}
                    onChange={setPaymentType}
                />
                <DDList
                    text_label="Currency"
                    placeholder=""
                    defaultValue={currency}
                    listOptions={currencyOptions.map((code) => ({value: code.id, label: code.value}))}
                    onChange={setCurrency}
                    isDisabled={!create}
                />

                <TextInput
                    text_label="Amount"
                    placeholder=""
                    type="number"
                    defaultValue={amount}
                    min={0}
                    onChange={(e) => setAmount(e.target.value)}
                />

                <ClientsDDList
                    value={client}
                    setClient={setClient}
                    setUserMessage={setUserMessage}
                />

                <ProjectsDDList
                    textLabel="Projects"
                    newItemLabel="+ New Project"
                    placeholder=""
                    defaultValues={projects ?? []}
                    username={username}
                    listOptions={[
                        {
                            label: "Assigned",
                            options: userProjectOptions?.length > 0 ?
                                userProjectOptions.map(project => ({label: project.project.name, value: project.project.id})) : []
                        },
                        {
                            label: "Unassigned",
                            options: projectOptions?.length &&
                                projectOptions
                                    .filter(project => userProjectOptions?.length > 0 ?
                                        !userProjectOptions.map(project => project.project.id).includes(project.id) : [])
                                    .map(project => ({label: project.name, value: project.id}))
                        }
                    ]}
                    onChange={setProjects}
                    withInfoIcon={false}
                    setShowAssignProjectPopup={setShowAssignProjectPopup}
                    setUserMessage={setUserMessage}
                />
                <SingleDatePicker
                    label="Start date"
                    className="single-datepicker-field"
                    defaultValue={startDate ? startDate : null}
                    setNewDate={setStartDate}
                />

                <SingleDatePicker
                    label="End date"
                    className="single-datepicker-field"
                    defaultValue={endDate ? endDate : null}
                    setNewDate={setEndDate}
                    reset={true}
                />

                <Textarea
                    text_label="Salary note"
                    value={note || ""}
                    onChange={e => setNote(e.target.value)}
                />
                {errorMessage ?
                    <div className="salary-error-message t-b4">
                        * {errorMessage?.message}
                    </div> :
                    <></>
                }

                <div className="popup-bottom">
                    <div className="popup-bottom-button-container">
                        <SmallButton btnType="secondary" onClick={saveSalaryDraft}>CANCEL</SmallButton>
                        <SmallButton type="submit" onClick={handleSubmit} disabled={isDisabledSubmit()}>SAVE</SmallButton>
                    </div>
                </div>
            </CustomPopup>

            <Popup
                open={!!(showAssignProjectPopup?.value || showAssignProjectPopup?.id)}
                closeOnDocumentClick
                onClose={() => setShowAssignProjectPopup(null)}
                modal
            >
                {close => (
                    <AssignProjectPopup
                        userId={userId}
                        project={showAssignProjectPopup}
                        username={username}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
        </>
    )
}

export default CreateUpdateSalaryPopup
