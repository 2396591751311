import {combineReducers} from "redux"

import authReducer from "../pages/Auth/reducers/authReducer"
import employeesReducer from "../pages/Employees/reducers/employeesReducer"
import profileReducer from "../pages/Profile/reducers/profileReducer"
import performanceReviewsReducer from "../pages/PerformanceReviews/reducers/performanceReviewsReducer"
import timeOffRequestsReducer from "../pages/TimeOffRequests/reducers/timeOffRequestsReducer"
import equipmentReducer from "../pages/Equipment/reducers/equipmentReducer"
import settingsReducer from "../pages/Settings/reducers/settingsReducer"
import timeTrackerDetailsReducer from "../pages/TimeTracker/reducers/timeTrackerDetailsReducer"
import calendarDataReducer from "../pages/TimeTracker/reducers/calendarDataReducer"
import timeRecordsReducer from "../pages/TimeTracker/reducers/timeRecordsReducer"
import userTimeRecordsReducer from "../pages/TimeRecords/reducers/timeRecordsReducer"
import reportsPageReducer from "../pages/Reports/reducers/reportsPageReducer"
import timeOffBalancesReducer from "../pages/TimeOffBalances/reducers/timeOffBalancesReducer"
import projectsReducer from "../pages/Projects/reducers/projectsReducer"
import employeePositionsReducer from "../pages/EmployeePositions/reducers/EmployeePositionsReducer"
import equipmentCategoriesReducer from "../pages/EquipmentCategories/reducers/EquipmentCategoriesReducer"
import publicDayOffsReducer from "../pages/PublicDayOffs/reducers/publicDayOffsReducer"
import dashboardNotificationsReducer from "../pages/Notifications/reducers/dashboardNotificationsReducer"
import userNotificationsReducer from "../pages/Notifications/reducers/userNotificationsReducer"
import salaryHistoryReducer from "../pages/SalaryHistory/reducers/salaryHistoryReducer"
import rolesReducer from "../pages/Roles/reducers/rolesReducer"
import clientsReducer from "../pages/Clients/reducers/ClientsReducer"
import paymentReportsReducer from "../pages/PaymentReports/reducers/paymentReportsReducer"
import {bonusReducer} from "../pages/SalaryHistory/reducers/bonusHistoryReducer"
import navigationReducer from "../layouts/Navs/reducers/navigationReducer"
import BonusEarnReducer from "../pages/Reports/reducers/BonusEarnReducer"
import SalaryEarnReducer from "../pages/Reports/reducers/SalaryEarnReducer"
import OvertimeEarnReducer from "../pages/Reports/reducers/OvertimeEarnReducer"
import onboardingRequestsReducer from "../pages/OnboardingRequests/reducers/onboardingRequestsReducer"
import DayTimeRecordsReducer from "../pages/Reports/reducers/DayTimeRecordsReducer"
import timeRecordTemplatesReducer from "../pages/TimeTracker/reducers/timeRecordTemplatesReducer"
import {newcoinsTransactionsReducer} from "../pages/SalaryHistory/reducers/newcoinsHistoryReducer"
import newcoinsBalancesReducer from "../pages/NewcoinsBalances/reducers/newcoinsBalancesReducer"
import productsReducer from "../pages/Products/reducers/productsReducer"
import {shopSlice} from "../pages/Shop/reducers/shopReducer"
import {productsApi} from "../api/products"
import createProductReducer from "../pages/CreateProduct/reducers/createProductReducer"
import purchaseRequestsReducer from "../pages/PurchaseRequests/reducers/purchaseRequestsReducer"
import viewProductReducer from "../pages/ViewProduct/reducers/viewProductReducer"
import personalPurchaseHistoryReducer from "../pages/PersonalPurchaseHistory/reducers/personalPurchaseHistoryReducer"
import couponsReducer from "../pages/Coupons/reducers/CouponsReducer"

/**
 * @desc Reducer function that invokes every reducer inside the passed object.
 * @const
 * @type {object}
 */
const rootReducer = combineReducers({
    auth: authReducer,
    employees: employeesReducer,
    timeOffRequests: timeOffRequestsReducer,
    onboardingRequests: onboardingRequestsReducer,
    timeOffBalances: timeOffBalancesReducer,
    equipment: equipmentReducer,
    profile: profileReducer,
    settings: settingsReducer,
    performanceReviews: performanceReviewsReducer,
    timeTrackerDetails: timeTrackerDetailsReducer,
    calendarData: calendarDataReducer,
    timeRecords: timeRecordsReducer,
    timeRecordTemplates: timeRecordTemplatesReducer,
    userTimeRecords: userTimeRecordsReducer,
    reportsPage: reportsPageReducer,
    projects: projectsReducer,
    employeePositions: employeePositionsReducer,
    equipmentCategories: equipmentCategoriesReducer,
    publicDayOffs: publicDayOffsReducer,
    dashboardNotifications: dashboardNotificationsReducer,
    userNotifications: userNotificationsReducer,
    salaryHistory: salaryHistoryReducer,
    roles: rolesReducer,
    coupons: couponsReducer,
    clients: clientsReducer,
    bonuses: bonusReducer,
    newcoinsTransactions: newcoinsTransactionsReducer,
    newcoinsBalances: newcoinsBalancesReducer,
    products: productsReducer,
    shop: shopSlice.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    createProduct: createProductReducer,
    navigation: navigationReducer,
    paymentReports: paymentReportsReducer,
    earnInfo: SalaryEarnReducer,
    bonusInfo: BonusEarnReducer,
    overtimeInfo: OvertimeEarnReducer,
    dayTimeRecords: DayTimeRecordsReducer,
    purchaseRequests: purchaseRequestsReducer,
    personalPurchaseHistory: personalPurchaseHistoryReducer,
    viewProduct: viewProductReducer,

})

export default rootReducer
