import React, {useState} from "react"

import {createClient, updateClient} from "../api"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import TextInput from "../../../toolkits/TextInput/TextInput"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"


const CreateUpdateClientPopup = ({client, setUserMessage, refreshTable, close}) => {
    const [name, setName] = useState(client?.name)

    const handleSubmit = () => {
        if (client) {
            updateClient(client.id, {name})
                .then(() => {
                    refreshTable()
                    setUserMessage({message: "Client was successfully updated!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                })
        } else {
            createClient({name})
                .then(() => {
                    refreshTable()
                    close()
                    setUserMessage({message: "Client was successfully created!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    console.log(error)
                    setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                })
        }
    }

    return (
        <CustomPopup text_title={`${client ? "Edit" : "Create"} Client`}>
            <TextInput
                text_label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!name}
                    >{client ? "SAVE" : "CREATE"}</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default CreateUpdateClientPopup
