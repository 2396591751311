import "./index.scss"

import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import Popup from "reactjs-popup"
import {useDispatch, useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import {receiveClients, receiveNextPageClients} from "./actions/ClientsActions"

import Protected from "../../components/Protected/Protected"
import ClientsTable from "./components/ClientsTable"
import CreateUpdateClientPopup from "./components/CreateUpdateClientPopup"

import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import SearchForm from "../../toolkits/SearchForm/SearchForm"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"

import {PAGE_SIZE} from "../../constants/other"
import {PERMISSIONS} from "../../constants/permissions"


const Clients = () => {
    const dispatch = useDispatch()

    const {
        tableLoading,
        clients,
        total,
    } = useSelector((state) => state.clients)
    
    const [currentPage, setCurrentPage] = useState(1)
    const [userMessage, setUserMessage] = useState(null)

    const [searchKey, setSearchKey] = useState(null)
    const [addNewClientPopup, setAddNewClientPopup] = useState(false)

    const getParams = (page) => ({
        page: page || currentPage,
        name: searchKey,
        size: PAGE_SIZE,
    })

    const getClients = (page) => {
        const params = getParams(page)

        dispatch(receiveClients(params))
        setCurrentPage((page || currentPage) + 1)
    }

    const getNextClients = () => {
        const params = getParams()

        dispatch(receiveNextPageClients(params))
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => { 
        getClients(1)
    }, [])

    useEffect(() => {
        if (searchKey !== null) {
            const delayDebounceFn = setTimeout(() => {
                getClients(1)
            }, 500)
            
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchKey])

    return (
        <>
            <Helmet>
                <title>Clients - Newsoft Inside</title>
            </Helmet>

            <div className="clients">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="clients-header">
                    <SearchForm
                        value={searchKey}
                        onChange={(e) => setSearchKey(e.target.value)}
                        onClick={() => setSearchKey("")}
                    />
                    <Protected permissions={[PERMISSIONS.WRITE_CLIENT]}>
                        <div className="clients-header-button">
                            <SmallButton onClick={() => setAddNewClientPopup(true)}> ADD NEW CLIENT </SmallButton>
                        </div>
                    </Protected>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={getNextClients}
                    hasMore={!tableLoading && clients.length < total}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={clients.length}
                    useWindow={false}
                >
                    {tableLoading ? <StyledCircularProgress/> :
                        <div className="clients-content">
                            <ClientsTable
                                clients={clients}
                                refreshTable={() => getClients(1)}
                                setUserMessage={setUserMessage}
                            />
                        </div>
                    }
                </InfiniteScroll>
            </div>

            <Popup open={addNewClientPopup} closeOnDocumentClick={false} onClose={() => setAddNewClientPopup(false)} modal>
                {close => (
                    <CreateUpdateClientPopup
                        refreshTable={() => getClients(1)}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>

        </>)
}

export default Clients
