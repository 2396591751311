import NavsLayout from "../layouts/Navs/NavsLayout"
import AuthLayout from "../layouts/Auth/AuthLayout"

import {Login, ResetPassword} from "../pages/Auth"
import Profile from "../pages/Profile"
import TimeTracker from "../pages/TimeTracker"
import Employees from "../pages/Employees"
import Targets from "../pages/Targets/Targets"
import Equipment from "../pages/Equipment"
import Settings from "../pages/Settings"
import TimeOffRequests from "../pages/TimeOffRequests"
import PerformanceReviews from "../pages/PerformanceReviews"
import TimeRecords from "../pages/TimeRecords"
import Reports from "../pages/Reports"
import Error from "../pages/Error"
import Dashboard from "../pages/Dashboard"
import TimeOffBalances from "../pages/TimeOffBalances"
import Projects from "../pages/Projects"
import EmployeePositions from "../pages/EmployeePositions"
import PublicDayOffs from "../pages/PublicDayOffs"
import EquipmentCategories from "../pages/EquipmentCategories"
import Roles from "../pages/Roles"
import Clients from "../pages/Clients"
import SalaryHistory from "../pages/SalaryHistory"
import PaymentReports from "../pages/PaymentReports"
import OnboardingRequests from "../pages/OnboardingRequests"
import NewcoinsBalances from "../pages/NewcoinsBalances"
import PurchaseRequests from "../pages/PurchaseRequests"

import {DASHBOARD_PERMISSIONS, PERMISSIONS, USER_PROFILE_PERMISSIONS} from "../constants/permissions"
import {SETTINGS} from "../constants/other"
import Products from "../pages/Products"
import CreateProduct from "../pages/CreateProduct"
import Shop from "../pages/Shop"
import ViewProduct from "../pages/ViewProduct"
import PersonalPurchaseHistory from "../pages/PersonalPurchaseHistory"
import Coupons from "../pages/Coupons"
import ClaimCoupon from "../pages/ClaimCoupon"


export const LoginRoute = {
    path: "/login",
    name: "Login",
    component: Login,
    layout: AuthLayout,
    protectedRoute: false
}

export const ResetPasswordRoute = {
    path: "/reset-password",
    name: "Reset passsword",
    component: ResetPassword,
    layout: AuthLayout,
    protectedRoute: false
}

export const ProfileRoute = {
    path: "/",
    name: "Profile",
    component: Profile,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [],
}

export const UserProfileRoute = {
    path: "/profile/:id/",
    name: "User profile",
    component: Profile,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: USER_PROFILE_PERMISSIONS,
}

export const TimeTrackerRoute = {
    path: "/time-tracker",
    name: "Time Tracker",
    component: TimeTracker,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [
        PERMISSIONS.READ_OWN_TIME_RECORD,
        PERMISSIONS.WRITE_OWN_TIME_OFF_REQUEST,
    ]
}

export const EmployeesRoute = {
    path: "/employees",
    name: "Employees",
    component: Employees,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_USER]
}

const TargetsRoute = {
    path: "/targets",
    name: "Targets",
    component: Targets,
    layout: NavsLayout,
    protectedRoute: true,
}

export const EquipmentRoute = {
    path: "/equipment",
    name: "Equipment",
    component: Equipment,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_EQUIPMENT],
}

export const EquipmentCategoriesRoute = {
    path: "/equipment-categories",
    name: "Equipment Categories",
    component: EquipmentCategories,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_EQUIPMENT],
}

export const SettingsRoute = {
    path: "/settings",
    name: "Settings",
    component: Settings,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.WRITE_SETTINGS],
}

export const TimeOffRequestsRoute = {
    path: "/time-off-requests",
    name: "Time Off Requests",
    component: TimeOffRequests,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [
        PERMISSIONS.READ_TIME_OFF_REQUEST,
        PERMISSIONS.READ_OWN_TIME_OFF_REQUEST,
    ]
}

export const PerformanceReviewsRoute = {
    path: "/performance-reviews",
    name: "Performance Reviews",
    component: PerformanceReviews,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [
        PERMISSIONS.READ_PERFORMANCE_REVIEW,
        PERMISSIONS.READ_OWN_PERFORMANCE_REVIEW,
    ]
}

export const ReportsRoute = {
    path: "/reports",
    name: "Time Reports",
    component: Reports,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_OWN_REPORTS,]
}

export const UserReportsRoute = {
    path: "/time-reports",
    name: "Time Reports",
    component: Reports,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_REPORTS,]
}

export const TimeRecordsRoute = {
    path: "/time-records",
    name: "Time Records",
    component: TimeRecords,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [
        PERMISSIONS.READ_TIME_RECORD,
        PERMISSIONS.READ_OWN_TIME_RECORD,
    ]
}

export const TimeRecordRequestsRoute = {
    path: "/time-record-requests",
    name: "Time Record Requests",
    component: TimeRecords,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_TIME_RECORD],
}

export const OnboardingRequestsRoute = {
    path: "/onboarding-requests",
    name: "Onboarding Requests",
    component: OnboardingRequests,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_ONBOARDING, PERMISSIONS.WRITE_ONBOARDING],
}

export const ErrorRoute = {
    path: "/error",
    name: "Error",
    component: Error,
    layout: null,
    protectedRoute: false,
}

export const DashboardRoute = {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: DASHBOARD_PERMISSIONS,
}

export const TimeOffBalancesRoute = {
    path: "/time-off-balances",
    name: "Time Off Balance",
    component: TimeOffBalances,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_TIME_OFF_BALANCE],
}

export const ProjectsRoute = {
    path: "/projects",
    name: "Projects",
    component: Projects,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_PROJECT],
}

export const EmployeePositionsRoute = {
    path: "/employee-positions",
    name: "Employee Positions",
    component: EmployeePositions,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_EMPLOYEE_POSITION],
}

export const PublicDayOffsRoute = {
    path: "/public-day-offs",
    name: "National Days Off",
    component: PublicDayOffs,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_CALENDAR_EVENTS],
}

export const SalaryHistoryRoute = {
    path: "/salary-history",
    name: "Payments",
    component: SalaryHistory,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [
        PERMISSIONS.READ_OWN_SALARY,
        PERMISSIONS.READ_SALARY,
        PERMISSIONS.READ_OWN_BONUS,
        PERMISSIONS.READ_BONUS,
    ],
}

export const RolesRoute = {
    path: "/roles",
    name: "Roles",
    component: Roles,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_ROLE],
}

export const ClientsRoute = {
    path: "/clients",
    name: "Clients",
    component: Clients,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_CLIENT],
}

export const PaymentReportsRoute = {
    path: "/payment-reports",
    name: "Payment Reports",
    component: PaymentReports,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_PAYMENT_REPORTS],
}

export const NewcoinsBalancesRoute = {
    path: "/newcoins-balance",
    name: "Newcoins Balance",
    component: NewcoinsBalances,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_NEWCOINS],
    settingsRequired: [SETTINGS.NEWCOINS_ENABLED]
}

export const CouponsRoute = {
    path: "/coupons",
    name: "Coupons",
    component: Coupons,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_COUPONS],
    settingsRequired: [SETTINGS.NEWCOINS_ENABLED]
}

export const ClaimCouponRoute = {
    path: "/coupons/claim",
    name: "Claim Coupon",
    component: ClaimCoupon,
    layout: null,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_OWN_COUPONS],
    settingsRequired: [SETTINGS.NEWCOINS_ENABLED]
}

export const ProductsRoute = {
    path: "/products",
    name: "Products",
    component: Products,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.WRITE_SHOP],
    settingsRequired: [SETTINGS.SHOP_ENABLED]
}

export const CreateProductRoute = {
    path: "/create-product",
    name: "Create Product",
    component: CreateProduct,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.WRITE_SHOP],
    settingsRequired: [SETTINGS.SHOP_ENABLED]
}

export const EditProductRoute = {
    path: "/edit-product/:id",
    name: "Edit Product",
    component: CreateProduct,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.WRITE_SHOP],
    settingsRequired: [SETTINGS.SHOP_ENABLED]
}

export const PurchaseRequestsRoute = {
    path: "/purchase-requests",
    name: "Purchase Requests",
    component: PurchaseRequests,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_PURCHASE_REQUEST],
    settingsRequired: [SETTINGS.SHOP_ENABLED]
}

export const PersonalPurchaseHistoryRoute = {
    path: "/purchase-history/:id",
    name: "Purchase History",
    component: PersonalPurchaseHistory,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_SHOP],
    settingsRequired: [SETTINGS.SHOP_ENABLED]
}

export const ShopRoute = {
    path: "/shop",
    name: "Shop",
    component: Shop,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_SHOP],
    settingsRequired: [SETTINGS.SHOP_ENABLED]
}

export const ViewProductRoute = {
    path: "/product/:id",
    name: "View Product",
    component: ViewProduct,
    layout: NavsLayout,
    protectedRoute: true,
    permissions: [PERMISSIONS.READ_SHOP],
    settingsRequired: [SETTINGS.SHOP_ENABLED]
}

export const routes = [
    LoginRoute,
    ResetPasswordRoute,
    ProfileRoute,
    UserProfileRoute,
    TimeTrackerRoute,
    EmployeesRoute,
    TargetsRoute,
    EquipmentRoute,
    SettingsRoute,
    TimeOffRequestsRoute,
    PerformanceReviewsRoute,
    TimeRecordsRoute,
    TimeRecordRequestsRoute,
    OnboardingRequestsRoute,
    ReportsRoute,
    UserReportsRoute,
    ErrorRoute,
    DashboardRoute,
    TimeOffBalancesRoute,
    ProjectsRoute,
    EmployeePositionsRoute,
    EquipmentCategoriesRoute,
    PublicDayOffsRoute,
    SalaryHistoryRoute,
    RolesRoute,
    ClientsRoute,
    PaymentReportsRoute,
    NewcoinsBalancesRoute,
    ProductsRoute,
    CreateProductRoute,
    EditProductRoute,
    PurchaseRequestsRoute,
    PersonalPurchaseHistoryRoute,
    ShopRoute,
    ViewProductRoute,
    CouponsRoute,
    ClaimCouponRoute
]
