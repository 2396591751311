import React, {useState} from "react"
import {Helmet} from "react-helmet"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import {PRODUCTS_SORT_OPTIONS} from "../../constants/other"
import "./index.scss"
import ShopProductCard from "./component/ShopProductCard"
import InfiniteScroll from "react-infinite-scroller"
import FilterPanel from "../../toolkits/FilterPanel"
import ShopHeader from "./component/ShopHeader"
import Lottie from "react-lottie"
import confettiAnimationData from "../../lotties/confetti.json"
import Protected from "../../components/Protected/Protected"
import {PERMISSIONS} from "../../constants/permissions"
import {EmptyResult} from "../../toolkits/EmptyResult/EmptyResult"
import DDFilter from "../../toolkits/DDFilter"
import useProducts from "./hooks/useProducts"
import {useDispatch, useSelector} from "react-redux"
import {getConfettiFired, getConfettiDisabled, setConfettiFired, disableConfetti} from "./reducers/shopReducer"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"

const Shop = () => {
    const confettiFired = useSelector(getConfettiFired)
    const confettiDisabled = useSelector(getConfettiDisabled)
    const dispatch = useDispatch()
    const [userMessage, setUserMessage] = useState(null)

    const {
        products,
        hasMore,
        loading,
        loadNextPage,
        categoryFilter,
        setCategoryFilter,
        sortBy,
        setSortBy,
        categoryStatuses,
    } = useProducts()

    const animationDefaultOptions = {
        loop: false,
        autoplay: true,
        animationData: confettiAnimationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    return (
        <>
            <div className={"shop-confetti"}>
                <Lottie eventListeners={[
                    {
                        eventName: "complete",
                        callback: () => dispatch(disableConfetti()),
                    },
                    {
                        eventName: "enterFrame",
                        callback: (frame) => {
                            const progress = frame.currentTime / frame.totalTime
                            if (confettiFired && progress < 0.1) {
                                dispatch(disableConfetti())
                            }
                            if (progress > 0.1) {
                                dispatch(setConfettiFired())
                            }
                        },
                    }
                ]} isStopped={confettiDisabled} options={animationDefaultOptions} isClickToPauseDisabled={true} width={"100%"} height={500}/>
            </div>
            <Helmet>
                <title> Products - Newsoft Inside </title>
            </Helmet>
            <div className="shop">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="shop-header">
                    <ShopHeader/>
                    <div className="shop-header-filters">
                        <FilterPanel
                            title="Category"
                            options={categoryStatuses}
                            selectedOption={categoryFilter}
                            setOptionFilter={setCategoryFilter}
                        />
                        <DDFilter
                            title={"Sort by"}
                            defaultValue={{label: sortBy.label, value: sortBy.value}}
                            options={(Object.values(PRODUCTS_SORT_OPTIONS)).map((o) => ({label: o.label, value: o.value}))}
                            onChange={setSortBy}
                        />
                    </div>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={loadNextPage}
                    hasMore={hasMore}
                    datalength={products.length}
                >
                    <div>
                        {products.length > 0 && (
                            <div className="shop-content">
                                {products.map((product) =>
                                    <Protected key={product.id}
                                        permissions={product.public ? [PERMISSIONS.READ_SHOP] : [PERMISSIONS.WRITE_SHOP]}>
                                        <ShopProductCard product={product} key={product.id}/>
                                    </Protected>
                                )}
                            </div>)
                        }
                        {!loading && products.length === 0 && <div className="shop-content-empty-result"><EmptyResult/></div>}
                        {loading && <StyledCircularProgress/>}
                    </div>
                </InfiniteScroll>
            </div>
        </>
    )
}

export default Shop