import {getClients} from "../api"

import {
    GET_CLIENTS_REQUEST,
    GET_CLIENTS_SUCCESS,
    GET_CLIENTS_FAILURE,
    GET_NEXT_PAGE_CLIENTS_REQUEST,
} from "../const"


const request = () => ({type: GET_CLIENTS_REQUEST})

const nextPageRequest = () => ({type: GET_NEXT_PAGE_CLIENTS_REQUEST})

const success = (clients) => ({
    type: GET_CLIENTS_SUCCESS,
    payload: {clients},
})

const failure = () => ({type: GET_CLIENTS_FAILURE})


export const receiveClients = (params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveClientsData} = await getClients(params)

        dispatch(success(receiveClientsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPageClients = (params) => async (dispatch) => {
    dispatch(nextPageRequest())
    try {
        const {data: receiveNextPageClientsData} = await getClients(params)
        dispatch(success(receiveNextPageClientsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
