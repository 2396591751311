import http from "../../http"

import {API_V1_URL} from "../../constants/other"


const COUPONS_URL = `${API_V1_URL}/coupons/`


export const getCoupons = (params) => http.get(COUPONS_URL, {params})

export const createCoupon = (payload) => http.post(COUPONS_URL, {data: payload})

export const updateCoupon = (couponId, data) => http.put(`${COUPONS_URL}${couponId}`, {data: data})

export const deactivateCoupon = (couponCode) => http.post(`${COUPONS_URL}${couponCode}/deactivate`)

export const claimCoupon = (params) => http.post(`${COUPONS_URL}claim`, {data: params})
