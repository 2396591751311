import React from "react"
import Popup from "reactjs-popup"
import DeleteProductPopup from "./DeleteProductPopup"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"
import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"
import DefaultProductImage from "../../../assets/images/user_photo.png"
import Newcoin from "../../../toolkits/Newcoin"
import {PRODUCT_STATUS} from "../../../constants/other"
import {useCustomHistory} from "../../../hooks/useCustomHistory"
import {EditProductRoute} from "../../../routes"


const ProductsTable = ({
    products,
    refreshTable,
    setUserMessage,
    isDesktop
}) => {
    const history = useCustomHistory()
    const productsList = products.map((product, i) => {
        const popupParams = {
            product: product,
            refreshTable: refreshTable,
            setUserMessage: setUserMessage,
        }

        const mainImage = product.images.length > 0 && product.images.find((image) => image.is_main === true)

        const tableActions = (
            <>
                <span onClick={(e) => {e.stopPropagation(), history.push(`${EditProductRoute.path.replace(":id", product.id)}`, {id: product.id, title: EditProductRoute.name})}} className="custom-scrollable-table-link">edit</span>

                <Popup trigger={<span className="custom-scrollable-table-link">delete</span>} modal>
                    {close => (
                        <DeleteProductPopup
                            {...popupParams}
                            close={close}
                        />
                    )}
                </Popup>
            </>
        )

        return (
            <tr onClick={() => history.push(`/product/${product.id}`, {title: product.name, edit: true})} key={`${i} ${product.id}`}
                className="custom-scrollable-table-row products-content-table-row">
                <td className="products-content-table-first-column">
                    <img src={mainImage?.image_url || DefaultProductImage} alt="Product Image"/>
                    <span className="t-s1">{product.name}</span>
                </td>
                <td>
                    <span className="t-b2">{product.category?.name}</span>
                </td>
                <td>
                    <span className={`t-b2 products-content-table-${[PRODUCT_STATUS.IN_STOCK, PRODUCT_STATUS.PRE_ORDER].includes(product.status) ? "green-span" : "red-span"}`}>{product.status}</span>
                </td>
                <td>
                    <span className="t-b2 products-content-table-price">{product.raw_price} <Newcoin type={"default"} /></span>
                </td>
                {isDesktop &&
                <td className="products-content-table-last-column">
                    {product.status !== PRODUCT_STATUS.DELETED ? tableActions : null}
                    {!product.public && <span className="products-content-table-unpublished-label t-b3">unpublished</span>}
                </td>}
            </tr>
        )
    })

    return (
        <CustomScrollableTable>
            <table className="products-content-table">
                <thead>
                    <tr>
                        <th><span className="custom-scrollable-table-head-span-first">NAME</span></th>
                        <th><span className="custom-scrollable-table-head-span">CATEGORY</span></th>
                        <th><span className="custom-scrollable-table-head-span">STATUS</span></th>
                        <th><span className="custom-scrollable-table-head-span">PRICE</span></th>
                        {isDesktop && <th><span className="custom-scrollable-table-head-span">ACTIONS</span></th>}
                    </tr>
                </thead>
                <tbody>
                    {productsList.length > 0 ? productsList : <EmptyResult/>}
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}

export default ProductsTable
